import React, { FC, ReactNode, useRef, useState } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton } from '@mui/material';
import { Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { DeleteBasket } from 'shared/assets/icons';
import { toBytesFromMB } from 'shared/utils';

import { CustomTooltip } from '../tooltip';

interface IProps {
  name: string;
  label?: string;
  isImage?: boolean;
  maxSize?: number;
  availableExtensions?: string[];
  methods: UseFormReturn<FieldValues, any, undefined>;
  infoTooltip?: ReactNode;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

export const UploadFile: FC<IProps> = ({
  methods,
  name,
  rules,
  label,
  isImage,
  maxSize,
  availableExtensions,
  infoTooltip
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const error = methods.formState.errors[name];

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (file: File) => {
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileBase64(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={methods.control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => {
          const isHaveFileBase64 = isImage && (fileBase64 || value);
          const isHaveFileAndError = file || value || error?.message;

          const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!e.target.files) return;

            const file = e.target.files[0];
            const type = file.type.split('/')[1];

            if (maxSize && file.size > toBytesFromMB(maxSize)) {
              methods.setError(name, { message: `Max size file ${maxSize}МБ`, type: 'deps' });
              onClearFile(true);
              return;
            }
            if (availableExtensions && !availableExtensions.includes(`.${type}`)) {
              methods.setError(name, {
                message: `Available extensions ${availableExtensions.join(',')}`,
                type: 'deps'
              });
              onClearFile(true);
              return;
            }
            methods.clearErrors(name);
            onChange(file);
            handleFileChange(file);
          };

          const onClearFile = (onlyInput?: boolean) => {
            if (inputRef.current) {
              inputRef.current.value = '';
            }
            if (onlyInput) return;
            onChange(null);
            setFile(null);
            setFileBase64(null);
            setTimeout(() => {
              methods.clearErrors(name);
            }, 0);
          };

          return (
            <>
              <input
                type='file'
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={onChangeFile}
                onBlur={onBlur}
                accept={availableExtensions?.join(',')}
              />
              <Box display={'flex'}>
                <Box display={'flex'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      fontWeight: 500,
                      cursor: 'pointer',
                      wordBreak: 'break-word',
                      flex: 1,
                      color: error ? '#b20000' : 'primary.main'
                    }}
                    display={'flex'}
                    gap={'6px'}
                    alignItems={'center'}
                    onClick={handleClick}>
                    {error?.message ? (
                      <>{!isHaveFileBase64 && <CloseOutlinedIcon sx={{ color: '#b20000' }} />}</>
                    ) : (
                      <>{!isHaveFileBase64 && <FileDownloadOutlinedIcon />}</>
                    )}

                    {isImage && (fileBase64 || value) && (
                      <Box
                        width={24}
                        height={24}
                        component={'img'}
                        sx={{ objectFit: 'cover' }}
                        src={fileBase64 || value}
                      />
                    )}
                    {error?.message && (
                      <Box component={'span'} sx={{ fontSize: 16, color: '#b20000' }}>
                        {String(error.message)}
                      </Box>
                    )}
                    {!error?.message && <>{value ? 'Bank logo' : file?.name || label}</>}
                  </Box>
                  {!!isHaveFileAndError && (
                    <IconButton
                      sx={{ padding: '2px', width: '24px', height: '24px' }}
                      onClick={() => onClearFile()}>
                      <DeleteBasket height={'20px'} />
                    </IconButton>
                  )}
                  {!isHaveFileAndError && infoTooltip && (
                    <CustomTooltip
                      placement='bottom-end'
                      title={
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'end'}
                          sx={{ span: { lineHeight: '20px' } }}>
                          {infoTooltip}
                        </Box>
                      }
                      optionsOffset={() => {
                        return [10, 0];
                      }}>
                      <IconButton
                        sx={{ padding: '2px', width: '24px', height: '24px' }}
                        onClick={() => onClearFile()}>
                        <InfoOutlinedIcon height={'20px'} />
                      </IconButton>
                    </CustomTooltip>
                  )}
                </Box>
              </Box>
            </>
          );
        }}
      />
    </Box>
  );
};
