import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';
import { useAuthStore } from 'app/store';
import { useNavigate } from 'react-router-dom';

import { useSwitchMethod } from 'widgets/methods/api';
import { COLUMNS } from 'widgets/methods/mock-data/MOCK_COL';

import { useGetApplications } from 'features/applications';

import { EditIcon, UnfoldMoreIcon } from 'shared/assets/icons';
import { useUser } from 'shared/hooks';
import { TApplications } from 'shared/services/types/application.types';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { Status } from 'shared/ui/status';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { TableLayout } from 'shared/ui/table-layout';
import { CustomTooltip } from 'shared/ui/tooltip';
import { addMessageToast, getTransformedDate } from 'shared/utils';

interface IProps extends PropsWithChildren {
  methodsInfo: { [key: string]: any };
  methodData: TMethods.MethodItem;
  onOpen: (id: number) => void;
}

export const MethodsItem: FC<IProps> = ({ methodsInfo, onOpen }) => {
  const navigate = useNavigate();
  const { rolesAccess } = useUser();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const defaultFilter = {
    size: pageSize,
    page: page,
    method_id: methodsInfo.id,
    order_by: 'id',
    is_desc_sort: true
  } as TApplications.GetApplication;

  const [filter, setFilter] = useState(defaultFilter);
  const [checked, setChecked] = useState(methodsInfo.isActive);
  const { switchMethod } = useSwitchMethod();

  const { user } = useAuthStore();

  const onlyAdmin = rolesAccess(['Администратор']);
  const adminWithManager = rolesAccess(['Администратор', 'Менеджер']);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      await switchMethod.mutateAsync({ id: methodsInfo.id });
      await switchMethod.mutateAsync({ id: methodsInfo.id });
      await setChecked(!event.target.checked);
      event.target.checked
        ? addMessageToast('Method successfully activated', 'success')
        : addMessageToast('Method successfully deactivated', 'success');
    } catch (error) {
      addMessageToast(error);
    }
  };

  const onFilterHandle = (key: string, value: number) => {
    const newFilter = { ...filter, [key]: value };
    setFilter(newFilter);
  };

  useEffect(() => {
    onFilterHandle('size', pageSize);
  }, [pageSize]);

  useEffect(() => {
    onFilterHandle('page', page);
  }, [page]);

  const { isApplicationsLoading, applications, isApplicationsError } = useGetApplications(filter);

  return (
    <Box
      px={12}
      sx={{
        bgcolor: '#fff',
        height: 1,
        borderRadius: 4,
        display: 'grid',
        gridTemplateRows: '72px auto',
        minHeight: '350px'
      }}>
      <Box
        sx={{
          maxWidth: 1,
          gridRow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: '0 15px',
          borderRadius: 8,
          py: 0
        }}>
        <Typography
          variant='h3'
          sx={{ flexGrow: 1, fontSize: 16, lineHeight: '24px', fontWeight: '500' }}>
          {methodsInfo.name}
        </Typography>
        {onlyAdmin && (
          <CustomTooltip
            title={
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'start'}
                sx={{ fontSize: '16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    maxWidth: '169px',
                    width: '169px',
                    margin: '0 6px',
                    '& span': {
                      fontWeight: 600
                    },
                    '& p': {
                      color: '#878787',
                      fontFamily: 'Roboto',
                      fontSize: '15px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '0.25px'
                    }
                  }}>
                  <Typography variant='body1' component='span'>
                    {checked ? 'Turn off method' : 'Turn on method'}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    {checked ? 'Turns off auto-matching' : 'Turns on auto-matching'}
                  </Typography>
                </Box>
              </Box>
            }
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [popper.width * 0.1, 0];
            }}>
            <Box>
              <StyledSwitch value={checked} setValue={handleChange} />
            </Box>
          </CustomTooltip>
        )}

        <Button
          variant='outlined'
          onClick={() => {
            navigate(`/method/${methodsInfo.id}`);
          }}
          sx={{
            p: '4px 10px',
            minWidth: '36px'
          }}>
          <UnfoldMoreIcon />
        </Button>
        {adminWithManager && (
          <CustomTooltip title='Edit method'>
            <Button
              variant='outlined'
              onClick={() => {
                onOpen(methodsInfo.id);
              }}
              sx={{
                p: '4px 10px',
                minWidth: '36px'
              }}>
              <EditIcon />
            </Button>
          </CustomTooltip>
        )}
      </Box>
      <Box
        sx={{
          width: '100%',
          gridRow: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          minHeight: '100%',
          '.MuiPaper-root > div': {
            border: '1px solid #E2EAF7'
          }
        }}>
        <TableLayout
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          rows={!isApplicationsLoading && !isApplicationsError ? applications?.items || [] : []}
          columns={COLUMNS}
          total={!isApplicationsLoading && !isApplicationsError ? applications?.total || 0 : 0}
          sx={{
            '.MuiTableHead-root .MuiTableCell-root': {
              borderBottom: '1px solid #E2EAF7'
            }
          }}>
          {isApplicationsLoading ? (
            <CircularProgressCenter top='120%' />
          ) : (
            <>
              {applications?.items.map((row, id) => (
                <TableRow key={id}>
                  <TableCell align='left'>
                    <Status status={row.status} />
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.id}
                  </TableCell>
                  <TableCell align='left'>{getTransformedDate(row?.timestamp)}</TableCell>
                  <TableCell align='left'>{row.transaction_id}</TableCell>
                  <TableCell align='left'>{row.account_id}</TableCell>
                  <TableCell align='left'>{row.subagent_id}</TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableLayout>
      </Box>
    </Box>
  );
};
