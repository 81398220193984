export const toFormatFileSize = (bytes: number) => {
  const mb = bytes / (1024 * 1024);

  const kb = bytes / 1024;
  if (kb > 102.4) {
    return `${mb.toFixed(2)} МБ`;
  } else {
    return `${kb.toFixed(2)} КБ`;
  }
};

export const toBytesFromMB = (mb: number) => {
  return mb * 1024 * 1024;
};
