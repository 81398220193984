import { FC, PropsWithChildren } from 'react';

import { Box, Button, Paper, Typography } from '@mui/material';

interface IProps extends PropsWithChildren {
  handleConfirmClose: () => void;
  handleCancelClose: () => void;
}

const CloseModal: FC<IProps> = ({ handleCancelClose, handleConfirmClose }) => {
  return (
    <Paper
      sx={{
        width: '631px',
        borderRadius: 4,
        overflow: 'visible',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '38px 24px 30px 24px',
        textAlign: 'center',
        gap: '30px'
      }}
      className='close-modal'>
      <Typography variant='h5'>
        Are you sure you want to close this window?
        <Typography
          sx={{
            color: '#74747E',
            fontSize: '22px',
            fontFamily: 'Roboto',
            fontWeight: '400',
            wordWrap: 'break-word',
            marginTop: '12px'
          }}>
          If you don't save, your changes will be lost
        </Typography>
      </Typography>
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
        <Button
          onClick={handleCancelClose}
          variant='contained'
          color='info'
          sx={{ width: '172px' }}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClose}
          variant='contained'
          color='primary'
          sx={{ width: '172px' }}>
          Close
        </Button>
      </Box>
    </Paper>
  );
};

export default CloseModal;
