import { useEffect, useState } from 'react';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, IconButton, MobileStepper, Modal, Paper, Typography } from '@mui/material';
import { useAuthStore } from 'app/store';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useCreateTwoFactor, useGetQR } from 'features/auth/api';

import { GoogleLogo } from 'shared/assets/icons';
import { REGEX } from 'shared/constants';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { addMessageToast } from 'shared/utils/addMessageToast';

import { QrScan } from './qr';
import styles from './two-factor.module.sass';

const TwoFactorAuth = () => {
  const methods = useForm({ mode: 'onBlur' });
  const { checkCode } = useCreateTwoFactor();
  const { toogleOtp } = useAuthStore();
  const onSubmit: SubmitHandler<TAuth.TwoFactorCheck> = async data => {
    try {
      await checkCode.mutateAsync(data);
      toogleOtp(true);
    } catch (error) {
      addMessageToast(error);
    }
  };
  const [isOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    activeStep === 0 && setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    activeStep === 1 && setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const { isQRLoading, getQR, error } = useGetQR();

  useEffect(() => {
    if (error) {
      addMessageToast(error);
    }
  }, [error]);
  return (
    <Modal
      open={isOpen}
      hideBackdrop={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className={styles.modal}>
      <Paper className={styles.paper}>
        <GoogleLogo style={{ marginTop: '10px' }} />
        <FormWrapper onSubmit={onSubmit} methods={methods}>
          {activeStep === 0 ? (
            <QrScan isQRLoading={isQRLoading} getQR={getQR} />
          ) : (
            <Box>
              <Typography variant='h5' sx={{ marginTop: '20px', marginBottom: '36px' }}>
                To verify your identity, enter the code generated by your two-factor authentication
                application
              </Typography>
              <Input
                name='code'
                placeholder='6-digit code'
                label='Authenticator code*'
                rules={{
                  required: 'Please, enter 6-digit-code.',
                  pattern: { value: REGEX.TWO_FACTOR, message: 'Please, enter 6-digit-code.' }
                }}
                methods={methods}
              />
              <Typography sx={{ textAlign: 'start', margin: '16px 4px' }} variant='body2'>
                The one-time code can be found in the Google Authentication app if you have set it
                up
              </Typography>
              <Button variant='contained' type='submit' fullWidth={true}>
                Continue
              </Button>
            </Box>
          )}
        </FormWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center'
          }}>
          <IconButton onClick={handleBack}>
            <KeyboardArrowLeft fontSize='large' color={activeStep === 0 ? 'disabled' : 'primary'} />
          </IconButton>
          <Box>
            <MobileStepper
              variant='dots'
              steps={2}
              position='static'
              activeStep={activeStep}
              sx={{ flexGrow: 1, backgroundColor: '#fff' }}
              nextButton={null}
              backButton={null}
            />
          </Box>
          <IconButton onClick={handleNext}>
            <KeyboardArrowRight
              fontSize='large'
              color={activeStep === 1 ? 'disabled' : 'primary'}
            />
          </IconButton>
        </Box>
      </Paper>
    </Modal>
  );
};

export default TwoFactorAuth;
