import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { useContainerHeight } from 'shared/hooks';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { TableLayout } from 'shared/ui/table-layout';

import { COLUMNS_USERS } from '../mock-data/COL_USERS';

import { UsersTableRow } from './UsersTableRow';

interface IProps {
  usersData?: TUsers.GetUsersSuccess;
  isUsersFetching?: boolean;
  isUsersLoading?: boolean;
  onEditUser: (data: TUsers.UserData) => void;
  methodsItems?: TMethods.MethodItem[];
  onToggleUsers?: (id: number) => void;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      page: number;
      size: number;
    }>
  >;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setModalAccountAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setUserMethodID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
}
export const TableUsers: FC<IProps> = ({
  usersData,
  isUsersLoading,
  onEditUser,
  methodsItems,
  setFilter,
  setSwitchItem,
  setModalAccountAdd,
  setUserMethodID,
  setUserID
}) => {
  const { height, ref } = useContainerHeight(15);
  const [size, setSize] = useState(20);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setFilter({ size, page });
  }, [size, page]);

  const customTableStyles = {
    '.MuiTableHead-root': {
      '.MuiTableCell-root': {
        background: '#D9E2FF',
        p: '18px 0',
        height: '20px',
        lineHeight: '20px',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        height: '20px',
        padding: '16px 0',
        borderBottom: '1px solid #BDC1D2',
        color: '#000',
        fontWeight: '500',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    }
  } as SxProps<Theme>;

  return (
    <Box
      gridRow={'2/4'}
      gridColumn={'2'}
      sx={{
        gap: 4,
        maxHeight: 1,
        overflow: 'clip'
      }}>
      <Box
        sx={{
          gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
          gridTemplateRows: '100%',
          gap: 4,
          overflowX: 'hidden'
        }}>
        <Box
          px={12}
          sx={{
            bgcolor: '#fff',
            borderRadius: 4,
            display: 'grid',
            gridTemplateRows: '0 auto',
            minHeight: '350px',
            p: '0',
            height: height
          }}
          ref={ref}>
          <Box
            sx={{
              width: '100%',
              gridRow: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              overflow: 'hidden',
              ...customTableStyles
            }}>
            <TableLayout
              pageSize={size}
              setPageSize={setSize}
              page={page}
              setPage={setPage}
              rows={usersData?.items || []}
              columns={COLUMNS_USERS}
              total={usersData?.total || 0}>
              {isUsersLoading ? (
                <CircularProgressCenter top='120%' />
              ) : (
                <>
                  {usersData?.items?.map((row, id) => {
                    return (
                      <UsersTableRow
                        id={id}
                        key={id + JSON.stringify(usersData?.items)}
                        methodsItems={methodsItems}
                        onEditUser={onEditUser}
                        row={row}
                        setSwitchItem={setSwitchItem}
                        usersData={usersData}
                        setModalAccountAdd={setModalAccountAdd}
                        setUserID={setUserID}
                        setUserMethodID={setUserMethodID}
                      />
                    );
                  })}
                </>
              )}
            </TableLayout>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
