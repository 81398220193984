import { useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useGetMethods } from 'widgets/methods';

import { useGetBanks } from 'features/banks/api';
import { BankModal } from 'features/banks/ui';

import { TBanks } from 'shared/services/types/banks.types';
import { HeaderWrapper } from 'shared/ui/header-wrapper';

import { TableBanks } from './slices/TableBanks';

export const Banks = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bankData, setBankData] = useState<TBanks.BankData | null>(null);
  const { methods: methodsItems } = useGetMethods();
  const [filters, setFilters] = useState({ page: 0, size: 20 });

  const { banksData, isBanksFetching, isBanksLoading } = useGetBanks({
    page: filters.page,
    size: filters.size,
    order_by: 'created_at'
  });

  const onEditBank = (data: TBanks.BankData) => {
    setBankData(data);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setBankData(null);
    }
  }, [isOpen]);

  const onCloseUserModal = () => {
    setIsOpen(false);
    setBankData(null);
  };

  return (
    <>
      <HeaderWrapper>
        <Typography
          variant='h3'
          color='primary'
          sx={{ flexGrow: 1, fontSize: 28, lineHeight: '36px' }}>
          Banks
        </Typography>

        <Button
          variant='contained'
          onClick={() => {
            setIsOpen(true);
          }}
          sx={{ padding: '10px 24px', paddingRight: '28px', maxWidth: 162 }}
          startIcon={<Add />}>
          Add bank
        </Button>
      </HeaderWrapper>

      <TableBanks
        methodsItems={methodsItems}
        setFilter={setFilters}
        isBankFetching={isBanksFetching}
        isBankLoading={isBanksLoading}
        banksData={banksData}
        onEditBank={(data: TBanks.BankData) => {
          onEditBank(data);
        }}
      />

      <BankModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        bankData={bankData!}
        onClose={onCloseUserModal}
      />
    </>
  );
};
