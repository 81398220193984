import { ChangeEvent, FC, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, TableCell, TableRow } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';

import { TAccounts } from 'shared/services/types/accounts.types';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { CustomTooltip } from 'shared/ui/tooltip';

interface IProps {
  id: number;
  row: TAccounts.UserAccountItem;
  accountsData: TAccounts.GetUserAccountsSuccess;
  onEditAccount: (data: TAccounts.UserAccountItem) => void;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
}

export const AccountsTableRow: FC<IProps> = ({
  id,
  accountsData,
  row,
  setSwitchItem,
  onEditAccount
}) => {
  const [checked, setChecked] = useState<boolean>();
  return (
    <TableRow key={JSON.stringify(row)}>
      <TableCell component='th' scope='row'>
        {++id + accountsData?.page * accountsData?.size}
      </TableCell>
      <TableCell align='left'>{row.account_name}</TableCell>
      <TableCell align='left'>{row.subagent_id}</TableCell>
      <TableCell align='left' sx={{ cursor: 'pointer' }}>
        <Box display={'flex'} gap={8} justifyContent={'flex-end'} sx={{ color: '#AEAAAB' }}>
          <CustomTooltip
            title={'Edit account'}
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [-popper.width * 0.3, 0];
            }}>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                onEditAccount(row);
              }}
              style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
            />
          </CustomTooltip>
          <CustomTooltip
            title={`Turn off the acceptance of applications and transactions for the account`}
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [-popper.width * 0.3, 0];
            }}>
            <Box>
              <StyledSwitch
                value={checked ?? row.is_active}
                setValue={e => {
                  setSwitchItem(e, row.id);
                }}
              />
            </Box>
          </CustomTooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};
