import { axiosInstance } from 'shared/api';
import { getBanksUrl } from 'shared/api/config/api.config';
import { createFormData } from 'shared/utils';

import { TBanks } from './types/banks.types';

export const BanksService = {
  async createBank(data: TBanks.CreateBank) {
    const response = await axiosInstance.post<TBanks.BankData>(
      getBanksUrl('/create-bank'),
      createFormData(data)
    );

    return response;
  },
  async editBank(data: TBanks.EditBank) {
    const response = await axiosInstance.patch<TBanks.GetBanksSuccess>(
      getBanksUrl('/update-bank'),
      createFormData(data)
    );

    return response;
  },

  async getBanks(data: TBanks.GetBanks) {
    const response = await axiosInstance.get<TBanks.GetBanksSuccess>(getBanksUrl('/get-banks'), {
      params: data
    });

    return response;
  }
};
