import { GridColDef } from '@mui/x-data-grid';

import { TTransaction } from 'shared/services/types/transaction.types';

export const TRANSACTIONS: GridColDef<TTransaction.TransactionItem>[] = [
  {
    field: 'status',
    headerName: 'transactions'
  },
  {
    field: 'id',
    headerName: 'ID'
  },
  {
    field: 'timestamp',
    headerName: 'Date'
  },
  {
    field: 'transaction',
    headerName: 'Transaction'
  },
  {
    field: 'account_id',
    headerName: 'Account'
  },
  {
    field: 'remarks',
    headerName: 'Remarks'
  },
  {
    field: 'income',
    headerName: 'Income'
  },
  {
    field: 'more',
    headerName: ''
  }
];
