import { axiosInstance } from 'shared/api';
import { getCurrencyUrl } from 'shared/api/config/api.config';

import { TCurrency } from './types/currency.types';

export const CurrencyService = {
  async getCurrencies() {
    const response = await axiosInstance.get<TCurrency.CurrencyResponse>(
      getCurrencyUrl('/currencies')
    );

    return response;
  }
};
