import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';

export const useGetMethods = () => {
  const {
    isLoading: isMethodsLoading,
    data: methods,
    isError: isMethodsError,
    ...restData
  } = useQuery({
    queryFn: () => MethodsService.getMethods({ size: 200, page: 0 }),
    queryKey: ['get methods'],
    retry: 0,
    select: ({ data }) => data.items
  });

  const sortMethods = useMemo(() => {
    const res = methods?.sort((a, b) => {
      if (a.is_active && !b.is_active) return -1;
      if (!a.is_active && b.is_active) return 1;
      return 0;
    });

    return res || [];
  }, [methods]);

  return { isMethodsLoading, methods, sortMethods, isMethodsError, ...restData };
};
