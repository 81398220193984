import { FC } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

interface IProps extends TooltipProps {
  optionsOffset?: Partial<any> | undefined;
}

export const CustomTooltip: FC<IProps> = ({ title, children, optionsOffset, ...props }) => {
  return (
    <Tooltip
      title={title}
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: optionsOffset
            }
          ]
        }
      }}
      disableInteractive
      {...props}>
      {children}
    </Tooltip>
  );
};
