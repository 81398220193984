import { FC, PropsWithChildren, useState } from 'react';

import {
  Button,
  Checkbox,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover
} from '@mui/material';
import { useSettingsStore } from 'app/store';

import { hasFormValuesChanged } from 'shared/utils';

interface IProps extends PropsWithChildren {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}

export const TableSettingsPopover: FC<IProps> = ({ anchorEl, setAnchorEl }) => {
  const { tableColumns, setTableColumns } = useSettingsStore();
  const [columns, setColumns] = useState(tableColumns);
  const isColumnsChanges = hasFormValuesChanged(tableColumns, columns);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Popover
      open={open}
      onClose={() => {
        handleClose();
        setColumns(tableColumns);
      }}
      anchorEl={anchorEl}
      elevation={4}
      sx={{
        '.MuiPaper-root': {
          borderTopRightRadius: '16px',
          borderBottomRightRadius: '16px',
          border: '1px solid #185AC2',
          borderRadius: '4px',
          color: '#44464F',
          width: '365px'
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}>
      {columns.map(value => {
        if (value.field === 'settings') return null;
        return (
          <ListItem disablePadding>
            <ListItemButton
              role={undefined}
              onClick={() =>
                setColumns(prev =>
                  prev.map(item => {
                    if (item.headerName === value.headerName) {
                      return { ...item, hideable: !item.hideable };
                    }
                    return item;
                  })
                )
              }
              sx={{ height: '48px', p: '14px 16px' }}
              dense>
              <ListItemIcon>
                <Checkbox edge='start' checked={!value.hideable} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText
                sx={{
                  '.MuiTypography-root': {
                    fontSize: '16px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                    fontWeight: '400'
                  }
                }}
                primary={value.headerName}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
      <Grid container sx={{ padding: '10px 16px', gap: '10px' }} justifyContent='space-between'>
        <Grid item>
          <Button
            onClick={() => {
              setColumns(tableColumns);
              handleClose();
            }}
            sx={{
              padding: 0,
              color: 'rgba(29, 27, 32, 0.60)',
              fontDamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px',
              letterSpacing: '0.04px'
            }}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!isColumnsChanges}
            onClick={() => {
              setTableColumns(columns);
              handleClose();
            }}
            sx={{
              padding: 0,
              color: '#185AC2',
              fontDamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px',
              letterSpacing: '0.04px',
              '&.Mui-disabled': {
                color: '#185AC2',
                opacity: '0.4'
              }
            }}>
            Ok
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};
