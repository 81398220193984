import { Children, FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box, LinearProgress } from '@mui/material';

interface IProps extends PropsWithChildren {
  headerTabs: { id: number; value: number }[];
  activeTab: number;
  onClick?: (id: number) => void;
  disabledHeader?: boolean;
}
export const Tabs: FC<IProps> = ({ headerTabs, children, activeTab, onClick, disabledHeader }) => {
  const [activeId, setActiveId] = useState<null | number>(activeTab || 0);

  const onClickActiveId = (id: number) => {
    if (!disabledHeader) {
      setActiveId(id);
      onClick?.(id);
    }
  };

  useEffect(() => {
    if (activeTab !== undefined) {
      onClickActiveId(activeTab);
    }
  }, [activeTab]);
  return (
    <div>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'18px'}>
        {headerTabs.map((item, id) => (
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'18px'}
            flexGrow={'1'}>
            <Box
              sx={[
                {
                  width: 30,
                  height: 30,
                  minWidth: 30,
                  minHeight: 30,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(24, 90, 194, 0.2)',
                  color: 'rgba(24, 90, 194, 0.40)'
                },
                activeTab >= id && {
                  backgroundColor: '#185AC2',
                  color: '#fff'
                }
              ]}>
              {id + 1}
            </Box>
            <LinearProgress
              variant='determinate'
              value={activeTab >= id ? item.value : 0}
              sx={{ width: '80%' }}
            />
          </Box>
        ))}
      </Box>
      <Box>
        {Children.map(children, (child, index) => {
          if (activeId === index) return <>{child}</>;
          return null;
        })}
      </Box>
    </div>
  );
};
