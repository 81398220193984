import { FC } from 'react';

import { TableCell } from '@mui/material';

import { MailIcon } from 'shared/assets/icons';
import { TCompare } from 'shared/services/types/compare.types';
import { Status } from 'shared/ui/status';
import { CustomTooltip } from 'shared/ui/tooltip';
import { getTransformedDate } from 'shared/utils';

interface IProps {
  row: TCompare.ResponseItem;
  openMessagesModal: (message: string) => void;
}

export const AppComparePart: FC<IProps> = ({ row, openMessagesModal }) => {
  return row.application_status === 'declined' ? (
    <>
      <TableCell
        align='left'
        sx={{
          position: 'relative',
          '&:after': {
            content: `''`,
            display: 'block',
            width: '1px',
            height: '100%',
            margin: '0 auto',
            background: '#BDC1D2',
            position: 'absolute',
            top: '0',
            left: '50%'
          }
        }}
      />
      <TableCell align='left'>
        <Status status={'declined'} />
      </TableCell>
      <TableCell colSpan={2} align='left'>
        Transaction not found
      </TableCell>
    </>
  ) : row.transaction_timestamp === null ? (
    <>
      <TableCell
        align='left'
        sx={{
          position: 'relative',
          '&:after': {
            content: `''`,
            display: 'block',
            width: '1px',
            height: '100%',
            background: '#BDC1D2',
            position: 'absolute',
            top: '0',
            left: '50%'
          }
        }}
      />
      <TableCell align='left'>
        <Status status={'in_progress'} />
      </TableCell>
      <TableCell colSpan={3} align='left'>
        Waiting for transaction
      </TableCell>
    </>
  ) : (
    <>
      <TableCell
        align='center'
        sx={{
          position: 'relative',
          '&:after': {
            content: `''`,
            display: 'block',
            width: '1px',
            height: '100%',
            background: '#BDC1D2',
            position: 'absolute',
            top: '0',
            left: '50%'
          }
        }}
      />
      <TableCell align='right'>
        <Status status={row.transaction_status} />
      </TableCell>
      <TableCell align='left'>
        {row.transaction_id === null ? 'Waiting for transaction' : row.transaction_id}
      </TableCell>
      <TableCell align='left'>
        {getTransformedDate(row?.transaction_timestamp, 'dd/MM/yy HH:mm:SS')}
      </TableCell>
      <TableCell
        align='right'
        sx={{
          cursor: ![row.transaction_id, row.application_id].includes(null) ? 'pointer' : 'default'
        }}>
        {![row.transaction_id, row.application_id].includes(null) && (
          <>
            <CustomTooltip
              title='Messages'
              placement='top-start'
              optionsOffset={() => {
                return [10, 0];
              }}>
              <>
                {!!row.transaction_message && (
                  <MailIcon
                    onClick={() => {
                      openMessagesModal(row?.transaction_message || '');
                    }}
                  />
                )}
              </>
            </CustomTooltip>
          </>
        )}
      </TableCell>
    </>
  );
};
