import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { CurrencyService } from 'shared/services/currency.service';

export const useGetCurrency = () => {
  const {
    isLoading: isCurrencyLoading,
    data: currency,
    isError: isCurrencyError,
    isFetching: isCurrencyFetching,
    ...restData
  } = useQuery({
    queryFn: () => CurrencyService.getCurrencies(),
    queryKey: ['get currency'],
    retry: 0,
    select: ({ data }) => data,
    placeholderData: keepPreviousData
  });

  return { isCurrencyLoading, currency, isCurrencyError, isCurrencyFetching, ...restData };
};
