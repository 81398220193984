import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { MethodsService } from 'shared/services/methods.service';
import { TCompare } from 'shared/services/types/compare.types';

export const useGetCompare = (data: TCompare.GetCompare) => {
  const {
    isLoading: isCompareLoading,
    data: compare,
    isError: isCompareError,
    isFetching: isCompareFetching,
    ...restData
  } = useQuery({
    queryFn: () => MethodsService.getCompare(data),
    queryKey: ['get compare table', data],
    retry: 0,
    select: ({ data }) => data,
    refetchInterval: 10000,
    placeholderData: keepPreviousData
  });

  return { isCompareLoading, compare, isCompareError, isCompareFetching, ...restData };
};
