import { useState } from 'react';

import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AddMethodModal } from 'features/add-method';
import { MethodsItem } from 'features/methods-item';
import { WithdrawalModal } from 'features/withdrawal';

import { useUser } from 'shared/hooks';
import { TMethods } from 'shared/services/types/methods.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { Layout } from 'shared/ui/layout';

import { useGetMethods } from '../api';

export const Methods = () => {
  const { isMethodsLoading, sortMethods: methodsList } = useGetMethods();
  const [methodData, setMethodData] = useState<TMethods.MethodItem | undefined>(undefined);

  const navigate = useNavigate();

  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);

  const handleOpenMethodModal = (id: number) => {
    setMethodData(methodsList?.find(method => method.id === id));
    setIsOpenAddMethod(true);
  };

  const { rolesAccess } = useUser();

  const isAccess = rolesAccess(['Администратор']);
  const adminWithManager = rolesAccess(['Администратор', 'Менеджер']);

  const onCloseMethodModal = () => {
    setIsOpenAddMethod(false);
    setMethodData(undefined);
  };

  return (
    <Layout>
      <Box
        gridColumn='2'
        gridRow='1'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 10,
          py: 7,
          px: 12,
          borderRadius: 4,
          bgcolor: '#fff',
          '.MuiButton-root': {
            padding: ' 10px 28px 10px 24px',
            minWidth: 'fit-content',
            maxHeight: 44,
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: '0.25px',
            '&.MuiButton-contained': {
              fontSize: '14px',
              fontWeight: '400'
            }
          }
        }}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '14px'
          }}>
          <Typography
            variant='h3'
            color='primary'
            sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '500' }}>
            Methods
          </Typography>
        </Box>
        {isAccess && (
          <Button
            variant='contained'
            startIcon={<Add />}
            onClick={() => {
              setIsOpenAddMethod(true);
            }}>
            Add method
          </Button>
        )}
      </Box>
      <Box
        gridRow={'2'}
        gridColumn={'2'}
        sx={{
          padding: '14px 24px',
          background: '#fff',
          borderRadius: '16px',
          overflowX: 'scroll'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 'none',
            height: '42px',
            gap: '16px'
          }}>
          {!methodsList ? (
            <Box
              sx={{
                minWidth: 1,
                minHeight: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {(methodsList || []).map((method: TMethods.MethodItem, id: number) => {
                if (!method.is_active && !adminWithManager) return null;
                return (
                  <ListItemButton
                    onClick={() => {
                      navigate(`/method/${method.id}`);
                    }}
                    sx={{
                      borderRadius: '100px',
                      p: '10px 32px',
                      m: 0,
                      minWidth: 'auto',
                      position: 'relative',
                      border: '1px solid rgba(24, 90, 194, 0.5)',
                      color: '#185AC2',
                      flexGrow: '0'
                    }}
                    key={id}>
                    <ListItemText
                      sx={{
                        m: 0,
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        letterSpacing: '0.25px',
                        '.MuiTypography-root': {
                          fontSize: '16px',
                          lineHeight: '20px',
                          width: '100%',
                          minWidth: '100%',
                          display: 'block'
                        }
                      }}
                      primary={method.name}
                    />
                  </ListItemButton>
                );
              })}
            </>
          )}
        </Box>
      </Box>
      <Box
        gridRow={'3/4'}
        gridColumn={'2'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridAutoRows: 'minmax(350px, calc(50% - 4px))',
          gap: 4,
          borderRadius: '16px',
          overflow: 'scroll',
          maxHeight: 'calc(100% - 24px)'
        }}>
        {isMethodsLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          (methodsList || []).map(method => (
            <MethodsItem
              methodData={method}
              methodsInfo={{ name: method.name, id: method.id, isActive: method.is_active }}
              key={method.id}
              onOpen={handleOpenMethodModal}
            />
          ))
        )}
      </Box>
      <AddMethodModal
        isOpen={isOpenAddMethod}
        onClose={onCloseMethodModal}
        methodData={methodData}
      />
      <WithdrawalModal isOpen={isOpenWithdrawal} setIsOpen={setIsOpenWithdrawal} />
    </Layout>
  );
};
