import { FC, PropsWithChildren, useRef } from 'react';

import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import {
  ApplicationsTableIcon,
  TableSettingsIcon,
  TransactionsTableIcon
} from 'shared/assets/icons';

import { CustomTooltip } from '../tooltip';

interface IProps extends PropsWithChildren {
  columns: GridColDef[];
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  sx?: SxProps<Theme>;
}

export const TableContentWrapper: FC<IProps> = ({ columns, sx, setAnchorEl, children }) => {
  const settingsRef = useRef();
  return (
    <Table
      stickyHeader
      sx={{
        minWidth: 0,
        width: '50%',
        borderBottom: 'none',
        position: 'relative',
        '::-webkit-scrollbar': {
          display: 'none',
          width: '0',
          background: 'transparent'
        },
        '.MuiCircularProgress-root': {
          width: '52px',
          height: '52px'
        },
        ...sx
      }}>
      <TableHead>
        <TableRow sx={{ borderRadius: '16px 16px' }}>
          {columns.map((column: any, id: number) => {
            return column.hideable ? null : (
              <TableCell
                key={id}
                align={'left'}
                sx={[
                  {
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    fontSize: 16,
                    lineHeight: '24px',
                    fontWeight: '500',
                    width: column.width,
                    maxWidth: column.maxWidth,
                    paddingRight: 0
                  },
                  column.field === 'divider' && {
                    '&:after': {
                      content: `''`,
                      display: 'block',
                      width: '1px',
                      height: '100%',
                      background: '#BDC1D2',
                      position: 'absolute',
                      top: '0',
                      left: '50%'
                    },
                    '&.MuiTableCell-root.MuiTableCell-head': {
                      borderBottom: 'none !important'
                    }
                  }
                ]}>
                {column.field === 'settings' ? (
                  <CustomTooltip title='Settings'>
                    <Box
                      sx={{
                        color: '#185AC2',
                        '&:hover': { color: '#3479E6' },
                        '&:active': { color: '#1C69E1' }
                      }}
                      ref={settingsRef}>
                      <TableSettingsIcon
                        style={{ display: 'block', margin: '0 auto' }}
                        onClick={() => setAnchorEl?.(settingsRef.current ?? null)}
                      />
                    </Box>
                  </CustomTooltip>
                ) : column.field === 'status' ? (
                  column.headerName === 'transactions' ? (
                    <CustomTooltip title='Transactions'>
                      <TransactionsTableIcon style={{ display: 'block' }} />
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip title='Applications'>
                      <ApplicationsTableIcon style={{ display: 'block' }} />
                    </CustomTooltip>
                  )
                ) : (
                  column.headerName
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody
        sx={{
          minHeight: 1,
          mt: '54px',
          '.MuiTableCell-root': {
            fontSize: 16,
            lineHeight: '24px'
          },
          '.status__wrapper': {
            margin: '0'
          }
        }}>
        {children}
      </TableBody>
    </Table>
  );
};
