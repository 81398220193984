import { useMutation, useQueryClient } from '@tanstack/react-query';

import { BanksService } from 'shared/services/banks.service';
import { TBanks } from 'shared/services/types/banks.types';

export const useActionsBank = () => {
  const queryClient = useQueryClient();

  const onCreateBank = useMutation({
    mutationFn: (data: TBanks.CreateBank) => BanksService.createBank(data),
    mutationKey: ['create banks'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get banks'] });
    }
  });

  const onUpdateBank = useMutation({
    mutationFn: (data: TBanks.EditBank) => BanksService.editBank(data),
    mutationKey: ['update banks'],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get banks'] });
    }
  });

  return { onCreateBank, onUpdateBank };
};
