import { FC, useMemo, useState } from 'react';

import { TableCell, TableRow } from '@mui/material';
import { useSettingsStore } from 'app/store';

import { useGetDate, useUser } from 'shared/hooks';
import { DateRange, TFilters, TStatusType } from 'shared/services/types/common.types';
import { TCompare } from 'shared/services/types/compare.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { MethodTableLayout } from 'shared/ui/method-table-layout';
import { TableContentWrapper } from 'shared/ui/table-content-wrapper';
import { TableSettingsPopover } from 'shared/ui/table-settings';

import { APPLICATIONS_COMPARE } from '../default-columns/APPLICATIONS_COMPARE';
import { TRANSACTIONS_COMPARE } from '../default-columns/TRANSACTIONS_COMPARE';

import { useGetCompare } from './api';
import { AppComparePart, TransComparePart } from './slices';

interface IProps {
  matchTransaction: (id: number) => void;
  page: number;
  pageSize: number;
  status?: TStatusType;
  accountNumber?: string;
  subagentNumber?: string;
  dateFilter: DateRange;
  methodID: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onSubmitFilters: (data: TFilters) => void;
  openMessagesModal: (message: string) => void;
}

export const CompareTables: FC<IProps> = ({
  matchTransaction,
  page,
  pageSize,
  status,
  accountNumber,
  subagentNumber,
  dateFilter,
  methodID,
  setPageSize,
  setPage,
  onSubmitFilters,
  openMessagesModal
}) => {
  const { rolesAccess } = useUser();
  const adminWithManager = rolesAccess(['Администратор', 'Менеджер']);
  const { getDate } = useGetDate();
  const { tableColumns } = useSettingsStore();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const filterC = useMemo(() => {
    return {
      size: pageSize,
      page: page,
      method_id: methodID.toString(),
      transaction_status: status ? status : undefined,
      subagent_id: subagentNumber,
      account_id: accountNumber,
      date_from: getDate(0, dateFilter),
      date_to: getDate(1, dateFilter)
    } as TCompare.GetCompare;
  }, [onSubmitFilters, page, pageSize]);

  const { isCompareLoading, compare } = useGetCompare(filterC);

  return (
    <MethodTableLayout
      pageSize={pageSize}
      setPageSize={setPageSize}
      page={page}
      setPage={setPage}
      total={compare?.total || 0}>
      <TableContentWrapper
        columns={[...APPLICATIONS_COMPARE, ...tableColumns, ...TRANSACTIONS_COMPARE]}
        setAnchorEl={setAnchorEl}
        sx={{
          tableLayout: 'fixed',
          p: '12px 16px',
          width: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          '.MuiTableHead-root': {
            '.MuiTableCell-root': {
              color: '#79747E',
              fontWeight: '600',
              borderBottom: '2px solid #BDC1D2'
            }
          },
          '.MuiTableCell-root:first-child': {
            p: '0 !important'
          },
          '.MuiTableRow-root': {
            '.MuiTableCell-root': {
              '&:nth-child(6)': {
                paddingLeft: '2px'
              },
              '&:nth-child(11)': {
                paddingRight: '2px'
              }
            }
          }
        }}>
        <TableSettingsPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        {isCompareLoading ? (
          <CircularProgressCenter top='120%' />
        ) : (
          <>
            {compare?.items.map((row, id) => {
              return (
                <TableRow key={id}>
                  <TransComparePart
                    matchTransaction={matchTransaction}
                    row={row}
                    isMatchHidden={adminWithManager}
                  />
                  {tableColumns.map((column: any) => {
                    if (column.hideable) return null;
                    return (
                      <TableCell
                        align='left'
                        sx={{
                          width: column.width,
                          maxWidth: column.maxWidth,
                          wordWrap: 'break-word'
                        }}>
                        {
                          //@ts-ignore
                          row[
                            (row.application_status === null ? 'transaction_' : 'application_') +
                              column.field
                          ]
                        }
                      </TableCell>
                    );
                  })}
                  <AppComparePart openMessagesModal={openMessagesModal} row={row} />
                </TableRow>
              );
            })}
          </>
        )}
      </TableContentWrapper>
    </MethodTableLayout>
  );
};
