import { GridColDef } from '@mui/x-data-grid';

import { TApplications } from 'shared/services/types/application.types';

export const APPLICATIONS: GridColDef<TApplications.ApplicationItem>[] = [
  {
    field: 'status',
    headerName: 'application'
  },
  {
    field: 'id',
    headerName: 'ID'
  },
  {
    field: 'timestamp',
    headerName: 'Date'
  },
  {
    field: 'transaction',
    headerName: 'Transaction'
  },
  {
    field: 'account_id',
    headerName: 'Account'
  },
  {
    field: 'remarks',
    headerName: 'Remarks'
  },
  {
    field: 'income',
    headerName: 'Income'
  },
  {
    field: 'more',
    headerName: ''
  }
];
