import { ChangeEvent, useEffect, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetMethods } from 'widgets/methods';

import { useGetUserAccounts } from 'features/accounts/api';
import { AddAccountModal } from 'features/add-account';

import { HeaderArrow } from 'shared/assets/icons';
import { useSortSwitches } from 'shared/hooks';
import { TAccounts } from 'shared/services/types/accounts.types';
import { HeaderWrapper } from 'shared/ui/header-wrapper';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { CustomTooltip } from 'shared/ui/tooltip';
import { addMessageToast } from 'shared/utils';

import { useSwitchAccounts } from './api';
import { TableAccounts } from './slices/TableAccounts';

export const UserAccounts = () => {
  const [isOpenAddAccount, setIsOpenAddAccount] = useState(false);
  const { methods: methodsList } = useGetMethods();
  const [accountData, setAccountData] = useState<TAccounts.UserAccountItem | undefined>(undefined);
  const [accsToSwitch, setAccsToSwitch] = useState('');
  const { idsAll, setSwitchItem, reset: switchesReset } = useSortSwitches();
  const { user_id, method_id } = useParams();
  const [currentMethod, setCurrentMethod] = useState<number>(parseInt(method_id!));
  const userID = parseInt(user_id!);
  const [filter, setFilter] = useState<TAccounts.GetUserAccount>({
    user_id: userID,
    method_id: currentMethod,
    page: 0,
    size: 100
  });

  const navigate = useNavigate();

  useEffect(() => {
    setFilter({
      user_id: userID,
      method_id: currentMethod,
      size: 100,
      page: 0,
      order_by: 'account_name'
    } as TAccounts.GetUserAccount);
  }, [currentMethod, userID]);

  const { accountsData, isAccountsLoading, isAccountsFetching } = useGetUserAccounts(filter);
  const { switchAccounts } = useSwitchAccounts();

  const [checked, setChecked] = useState(false);

  const handleSwitch = async (event?: ChangeEvent<HTMLInputElement>, ids?: string) => {
    try {
      await switchAccounts.mutateAsync({ ids: ids ?? accsToSwitch });
      const isChecked = event?.target.checked;
      addMessageToast('Accounts successfully switched', 'success');
      switchesReset();
    } catch (error) {
      addMessageToast(error);
    }
  };

  const onCloseAccountsModal = () => {
    setIsOpenAddAccount(false);
    setAccountData(undefined);
  };

  const onActionUser = (type: 'edit' | 'delete', data: TAccounts.UserAccountItem) => {
    if (type === 'edit') {
      setAccountData(data);
      setIsOpenAddAccount(true);
    } else {
      // TODO удаление пользователя/пользователей
    }
  };

  useEffect(() => {
    switchesReset();
  }, [filter]);

  useEffect(() => {
    if (!isOpenAddAccount) {
      setAccountData(undefined);
    }
  }, [isOpenAddAccount]);

  useEffect(() => {
    if (accountsData?.items) {
      const isAccsChecked = accountsData.items.filter(acc => acc.is_active);
      setChecked(!!isAccsChecked.length);
      const ids = Array.from(accountsData.items)
        .filter(acc => acc.is_active === checked)
        .map(acc => acc.id)
        .join(',');
      setAccsToSwitch(ids);
    }
  }, [filter, accountsData, checked]);

  return (
    <>
      <HeaderWrapper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            flexGrow: '1',
            gap: '10px',
            cursor: 'pointer'
          }}
          onClick={() => {
            navigate(-1);
          }}>
          <Box sx={{ height: '44px', color: 'primary.main' }}>
            <HeaderArrow
              style={{
                marginTop: '11.5px'
              }}
            />
          </Box>
          <Typography
            variant='h3'
            color='primary'
            sx={{ fontSize: 28, lineHeight: '36px', fontWeight: '400' }}>
            User accounts
          </Typography>
        </Box>
        <>
          {accountsData && accountsData.items.length > 0 && (
            <CustomTooltip
              title={`Turn off the acceptance of applications and transactions for the all accounts`}
              optionsOffset={({ popper }: { popper: ClientRectObject }) => {
                return [-popper.width * 0.3, 0];
              }}>
              <Box>
                <StyledSwitch
                  key={checked.toString()}
                  value={checked}
                  setValue={e => {
                    handleSwitch(e);
                  }}
                />
              </Box>
            </CustomTooltip>
          )}
          {!!idsAll.length && (
            <Button
              variant='contained'
              onClick={async () => {
                const idsString = idsAll.map(i => i.id).join(',');

                handleSwitch(undefined, idsString);
              }}
              sx={{ p: '10px 28px' }}>
              Save сhanges
            </Button>
          )}
          <Button
            variant={idsAll.length ? 'outlined' : 'contained'}
            onClick={() => {
              setIsOpenAddAccount(true);
            }}
            sx={{ p: '10px 24px', maxWidth: 162 }}
            startIcon={<Add />}>
            Add account
          </Button>
        </>
      </HeaderWrapper>

      <TableAccounts
        key={accsToSwitch}
        methods={methodsList}
        setFilter={setFilter}
        setModalAccountAdd={setIsOpenAddAccount}
        isAccountsFetching={isAccountsFetching}
        accountsData={accountsData}
        isAccountsLoading={isAccountsLoading}
        setSwitchItem={setSwitchItem}
        onEditAccount={(data: TAccounts.UserAccountItem) => {
          onActionUser('edit', data);
        }}
      />

      <AddAccountModal
        isOpen={isOpenAddAccount}
        setIsOpen={setIsOpenAddAccount}
        onClose={onCloseAccountsModal}
        methodsList={methodsList!}
        methodID={currentMethod}
        userId={userID}
        accountData={accountData}
      />
    </>
  );
};
