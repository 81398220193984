import { FC } from 'react';

import { Add } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField
} from '@mui/material';
import { Controller, FieldValues, RegisterOptions, UseFormReturn } from 'react-hook-form';

import { LogoIcon } from 'shared/assets/icons';
import { TSelectOption } from 'shared/services/types/common.types';

interface IProps {
  isOptionsLoading?: boolean;
  options?: TSelectOption[];
  name: string;
  inputLabel: string;
  maxHeight?: number;
  inputPlaceholder: string;
  methods: UseFormReturn<FieldValues, any, undefined>;
  headerProps?: {
    onClick: () => void;
    label: string;
  };
  key?: React.Key | null | undefined;
  isMulti?: boolean;
  isFooter?: boolean;
  disabled?: boolean;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

export const SelectSearch: FC<IProps> = ({
  options,
  isOptionsLoading,
  name,
  inputLabel,
  inputPlaceholder,
  methods,
  key,
  headerProps,
  isMulti,
  isFooter,
  disabled,
  maxHeight = 350,
  rules
}) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const fieldError = methods?.formState.errors[name]?.message?.toString();

  const resetField = () => {
    methods?.setValue(name, '');
  };

  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={methods.control}
        name={name}
        rules={rules}
        render={({ field: { onChange, onBlur, value, ref } }) => {
          const currValue = options?.find(item => {
            if (Array.isArray(value)) return value;

            return [value, value?.value].includes(item?.value);
          });

          const valueIsEmpty = Array.isArray(value) ? !value.length : !value;

          const valueSelect = () => {
            if (['string', 'number'].includes(typeof value)) {
              return value;
            }

            return value || [];
          };

          return (
            <Autocomplete
              multiple={isMulti}
              disabled={disabled}
              value={isMulti ? value : currValue}
              onBlur={onBlur}
              onChange={(event, newValue) => {
                if (Array.isArray(newValue)) {
                  onChange(
                    newValue?.map(item => {
                      return ['string', 'number'].includes(typeof item) ? item : item.value;
                    })
                  );
                } else {
                  onChange(newValue?.value || null);
                }
              }}
              // TODO переделать
              // onInputChange={(e, inputValue) => {
              //   if (e?.target && e.type !== 'blur') {
              //     console.log(inputValue);
              //   }
              // }}
              options={options ?? []}
              disableCloseOnSelect={isMulti}
              limitTags={isMulti ? 1 : undefined}
              getOptionLabel={option => {
                return option.label ?? '';
              }}
              renderTags={(value: readonly TSelectOption[] | string[], getTagProps) =>
                value.map((option: TSelectOption | string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip
                      variant='filled'
                      label={typeof option === 'string' ? option : option.label}
                      key={key}
                      {...tagProps}
                    />
                  );
                })
              }
              renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                  <MenuItem {...optionProps} key={option.value}>
                    {isMulti && (
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                    {option.extra &&
                      (option.extra?.icon ? (
                        <Box
                          component={'img'}
                          width={20}
                          height={20}
                          src={option.extra?.icon}
                          alt='icon'
                          sx={{
                            flex: '0 0 20px',
                            display: 'flex',
                            objectFit: 'contain',
                            marginRight: 4
                          }}
                        />
                      ) : (
                        <LogoIcon
                          height={20}
                          width={20}
                          style={{
                            flex: '0 0 20px',
                            display: 'flex',
                            objectFit: 'contain',
                            marginRight: 8
                          }}
                        />
                      ))}
                    {option.label}
                  </MenuItem>
                );
              }}
              ListboxComponent={props => (
                /* 
         Кастомный хедер
        */
                <>
                  <Paper
                    {...props}
                    sx={{
                      maxHeight: `${maxHeight} !important`,
                      padding: '0 !important',
                      border: '1px solid #185AC2 !important'
                    }}
                    elevation={0}>
                    {/* 
              Кастомный хедер
            */}

                    {!!headerProps && (
                      <Grid
                        container
                        sx={{
                          padding: '4px 16px',
                          borderBottom: '1px solid rgba(24, 90, 194, 0.08)',
                          gap: '10px'
                        }}
                        justifyContent='space-between'>
                        <Button
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: '0',
                            fontSize: '16px',
                            '&:hover': { backgroundColor: 'unset' }
                          }}
                          endIcon={<Add />}
                          onClick={headerProps.onClick}>
                          {headerProps.label}
                        </Button>
                      </Grid>
                    )}
                    {props.children}

                    {/* 
              Кастомный футер
            */}
                    {isFooter && (
                      <Grid
                        container
                        sx={{ padding: '10px 16px', gap: '10px' }}
                        justifyContent='space-between'>
                        <Grid
                          item
                          sx={{
                            color: 'rgba(29, 27, 32, 0.50)',
                            fontDamily: 'Roboto',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.04px'
                          }}>
                          <Button sx={{ padding: 0 }}>Cancel</Button>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            color: 'rgba(24, 90, 194, 0.20)',
                            fontDamily: 'Roboto',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.04px'
                          }}>
                          <Button sx={{ padding: 0 }}>Ok</Button>
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                </>
              )}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    label={inputLabel}
                    value={valueSelect()}
                    placeholder={isMulti && !valueIsEmpty ? '' : inputPlaceholder}
                    // // error={!!errors?.[name]}
                    InputLabelProps={{
                      style: { fontSize: 12 },
                      shrink: true
                    }}
                    sx={{
                      '.MuiFormLabel-root': { color: fieldError && '#b20000' },
                      '.MuiAutocomplete-endAdornment': {
                        marginRight: '7px',
                        '.MuiAutocomplete-clearIndicator': {
                          visibility:
                            methods?.watch(name) && !isOptionsLoading ? 'visible' : 'hidden'
                        },
                        '.MuiAutocomplete-popupIndicator': {
                          display: methods?.watch(name) ? 'none' : 'inline-flex'
                        }
                      },
                      fieldset: {
                        border: fieldError && '1px solid #b20000 '
                      }
                    }}
                    variant='outlined'
                    fullWidth
                  />
                );
              }}
            />
          );
        }}
      />
      <Box sx={{ position: 'absolute', right: 44, top: 16, display: 'flex', alignItems: 'center' }}>
        {isOptionsLoading && methods?.watch(name) && (
          <CircularProgress
            sx={{
              width: '24px !important',
              height: '24px !important',
              '.MuiCircularProgress-svg': { width: '24px', height: '24px' }
            }}
          />
        )}
      </Box>
      <Box
        style={{
          marginLeft: '16px',
          fontSize: 12,
          color: '#b20000',
          textAlign: 'start',
          fontWeight: 'normal'
        }}>
        {(fieldError && <span>{fieldError}</span>) || fieldError}
      </Box>
    </Box>
  );
};
