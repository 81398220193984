import { GridColDef } from '@mui/x-data-grid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { COMPARE } from 'entities/method-tables/default-columns/COMPARE';

import { TCompare } from 'shared/services/types/compare.types';

interface StoreState {
  testMode: boolean;
  toogleTestMode: (testMode: boolean) => void;
  tableColumns: GridColDef<TCompare.ResponseItem>[];
  setTableColumns: (tableColumn: GridColDef<TCompare.ResponseItem>[]) => void;
}

const useSettingsStore = create<StoreState>()(
  persist(
    set => ({
      testMode: true,
      toogleTestMode: testMode => set({ testMode: testMode }),
      tableColumns: COMPARE,
      setTableColumns: tableColumns => set({ tableColumns: tableColumns })
    }),
    {
      name: 'settings-storage'
    }
  )
);

export default useSettingsStore;
