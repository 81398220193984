import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Box, Paper, SxProps, TableContainer, Theme } from '@mui/material';
import useSettingsStore from 'app/store/useSettingsStore';
import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';

import useScrollPercentage from 'shared/hooks/useScrollPercentage';
import { TablePagination } from 'shared/ui/pagination';

interface IProps extends PropsWithChildren {
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  sx?: SxProps<Theme>;
}

export const MethodTableLayout: FC<IProps> = ({
  children,
  pageSize,
  setPageSize,
  page,
  setPage,
  total,
  sx
}) => {
  const { testMode } = useSettingsStore();
  const { handleScroll, handleThumbDrag, scrollbarRef, customScrollRef, customScrollThumbsRef } =
    useScrollPercentage();
  const [showScroll, setShowScroll] = useState(false);
  useEffect(() => {
    if (total) {
      setShowScroll(true);
    }
  }, [total]);

  return (
    <Box
      sx={{
        ...sx,
        gridRow: '2/4',
        gridColumn: '2'
      }}>
      <Box
        sx={{
          scrollbarColor: '#D9E2FF #E9ECF5',
          overflow: 'auto',
          '&.MuiBox-root': {
            maxWidth: '100%',
            width: '100%',
            height: 'calc(100% - 83px)',
            marginBottom: '11px'
          }
        }}>
        <TableContainer
          className={clsx('table__small')}
          sx={{
            height: 1,
            boxShadow: 'none',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'hidden',
            '& > div:first-child > div': {
              background: '#fff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '1px'
            },
            '&:after': {
              content: testMode ? undefined : `''`,
              width: '1px',
              height: '97%',
              position: 'absolute',
              top: '1.5%',
              left: '49.75%',
              transform: 'translate(-50%, 0%)',
              zIndex: '100',
              background: '#E2EAF7'
            },
            '::-webkit-scrollbar': {
              display: 'none',
              width: '0',
              background: 'transparent',
              scrollbarColor: 'transparent transparent'
            }
          }}
          component={Paper}>
          <Scrollbars
            ref={scrollbarRef}
            style={{
              color: '#fff',
              width: '100%',
              borderRadius: '16px',
              height: 'calc(100% - .4vh)',
              scrollbarColor: 'transparent transparent'
            }}
            renderTrackVertical={() => {
              return <Box sx={{ display: 'none' }} />;
            }}
            renderThumbVertical={() => {
              return <Box sx={{ display: 'none' }} />;
            }}
            onScroll={handleScroll}>
            {children}
          </Scrollbars>
          {showScroll && (
            <Box
              ref={customScrollRef}
              style={{
                width: '8px',
                position: 'absolute',
                top: '62px',
                right: '4px',
                height: 'calc(100% - 72px)'
              }}>
              <Box
                ref={customScrollThumbsRef}
                style={{
                  width: '100%',
                  background: 'rgba(139, 172, 224, 0.5)',
                  borderRadius: '16px',
                  position: 'absolute',
                  cursor: 'pointer',
                  top: '0',
                  height: '30%' // Initial height
                }}
                onMouseDown={handleThumbDrag}
              />
            </Box>
          )}
        </TableContainer>
      </Box>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        total={total}
      />
    </Box>
  );
};
