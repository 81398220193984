import { ChangeEvent, FC, useEffect, useState } from 'react';

import { UpdateOutlined } from '@mui/icons-material';
import {
  Box,
  Divider,
  Paper,
  Popover,
  PopoverVirtualElement,
  Stack,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import useSettingsStore from 'app/store/useSettingsStore';

import { CloseIcon } from 'shared/assets/icons';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { addMessageToast } from 'shared/utils';

interface IProps {
  open: boolean;
  anchorEl:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  handleClose: () => void;
}

const SettingsPopover: FC<IProps> = ({ open, anchorEl, handleClose }) => {
  const [value, setValue] = useState<number | undefined>();
  const { testMode, toogleTestMode } = useSettingsStore();

  const handleSwitch = async (event?: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event?.target.checked;
    toogleTestMode(!!isChecked);
  };

  useEffect(() => {
    if (open)
      addMessageToast(`New version of table ${testMode ? 'included' : 'excluded'}`, 'success');
  }, [testMode]);

  const tabData = [
    {
      label: 'Update',
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography component={'p'} fontSize={'18px'} lineHeight={'21px'}>
            Include new table version
          </Typography>
          <Box>
            <StyledSwitch
              value={testMode}
              setValue={e => {
                handleSwitch(e);
              }}
            />
          </Box>
        </Box>
      ),
      icon: <UpdateOutlined />
    }
  ];

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      elevation={4}
      sx={{
        '&>.MuiPaper-root': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomRightRadius: '16px',
          marginLeft: '8px'
        },
        '.MuiBackdrop-root.MuiBackdrop-invisible.MuiModal-backdrop': {
          backgroundColor: 'rgba(20.01, 20.01, 35.06, 0.50)'
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      <Box
        sx={{
          display: 'block',
          position: 'absolute',
          top: '16px',
          right: '17px',
          width: 25,
          height: 25,
          color: '#44464F',
          cursor: 'pointer'
        }}
        onClick={handleClose}>
        <CloseIcon />
      </Box>
      <Stack direction='row' minHeight='260px'>
        <Box sx={{ minWidth: '228px', display: 'flex', flexDirection: 'column' }}>
          <Typography
            component={'p'}
            sx={{
              color: '#185AC2',
              fontSize: '26px',
              fontFamily: 'Roboto',
              fontWeight: '500',
              lineHeight: '36px',
              wordWrap: 'break-word',
              p: '12px 24px',
              textTransform: 'none'
            }}>
            Settings
          </Typography>
          <Tabs
            orientation='vertical'
            value={value}
            onChange={(_event, newValue) => setValue(newValue)}>
            {tabData.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  p: 0,
                  pl: '24px',
                  alignItems: 'flex-start',
                  color: '#185AC2',
                  '&:hover': {
                    background: 'rgba(52, 121, 230, 0.14)'
                  },
                  '&:active': {
                    background: 'rgba(28, 105, 225, 0.18)'
                  },
                  '&.Mui-selected': {
                    color: '#185AC2',
                    background: 'rgba(28, 105, 225, 0.18)'
                  }
                }}
                label={
                  <Box>
                    <Stack direction='row' gap={1}>
                      {tab.icon}
                      <Typography
                        whiteSpace='nowrap'
                        sx={{
                          textAlign: 'left',
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: 'normal',
                          textTransform: 'none'
                        }}>
                        {tab.label}
                      </Typography>
                    </Stack>
                  </Box>
                }
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        {tabData.map(
          (tab, index) =>
            value === index && (
              <Paper sx={{ minWidth: '322px' }}>
                <Typography
                  component={'p'}
                  sx={{
                    color: '#185AC2',
                    fontSize: '26px',
                    fontFamily: 'Roboto',
                    fontWeight: '500',
                    lineHeight: '36px',
                    wordWrap: 'break-word',
                    p: '12px 24px'
                  }}>
                  {tab.label}
                </Typography>
                <Box
                  sx={{
                    p: '12px 24px',
                    color: '#44464F',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal'
                  }}>
                  {tab.content}
                </Box>
              </Paper>
            )
        )}
      </Stack>
    </Popover>
  );
};

export default SettingsPopover;
