import { FC, useEffect, useMemo, useState } from 'react';

import { IStepProps } from '..';
import { Box, Button, Grid } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { useGetCurrency } from 'features/add-method/api';
import { useGetBanks } from 'features/banks/api';
import { BankModal } from 'features/banks/ui';

import { REGEX } from 'shared/constants';
import { TBanks } from 'shared/services/types/banks.types';
import { TMethods } from 'shared/services/types/methods.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { IModalProps, ModalTemplate } from 'shared/ui/modal-template';
import { SelectSearch } from 'shared/ui/select-multi';
import { Tabs } from 'shared/ui/tabs';
import { hasFormValuesChanged } from 'shared/utils';

interface IProps extends IModalProps, IStepProps {
  onRegexEditor?: () => void;
  onSubmit: (data: any) => void;
  formOne: UseFormReturn<FieldValues, any, undefined>;
  formTwo: UseFormReturn<FieldValues, any, undefined>;
  activeTab: number;
  methodData?: TMethods.MethodItem;
}

// TODO варианты transaction source
// const SOURCE_LIST = [
//   { value: 'sms_push', label: 'SMS/push' },
//   { value: 'email', label: 'Email' },
//   { value: 'api', label: 'API' }
// ];

export const MainInfoModal: FC<IProps> = ({
  close,
  isOpen,
  onRegexEditor,
  onSubmit,
  formOne,
  formTwo,
  onNext,
  activeTab,
  methodData
}) => {
  const { currency, isCurrencyLoading } = useGetCurrency();

  //TODO пока пропускает проверку регулярок
  const isConfirmationNeeded = hasFormValuesChanged(formOne.watch(), methodData ?? {}, [
    'id',
    'transaction_source',
    'regex'
  ]);

  const { isBanksLoading, isBanksFetching, banksData } = useGetBanks({ page: 0, size: 250 });

  const banksLoaded = useMemo(() => {
    return (
      banksData?.items?.map(bank => {
        return { label: bank.name, value: bank.id.toString(), extra: { icon: bank.icon_file } };
      }) || []
    );
  }, [banksData]);

  const [activeId, setActiveId] = useState(activeTab || 0);
  const [barPercent, setBarPercent] = useState(0);
  const [barPercent2, setBarPercent2] = useState(0);
  const [createdBank, setCreatedBank] = useState<number>();

  const [bankData, setBankData] = useState<TBanks.BankData | null>(null);
  const [isBankOpen, setIsBankOpen] = useState(false);
  const onCloseUserModal = () => {
    setIsBankOpen(false);
    setBankData(null);
  };

  useEffect(() => {
    const requiredFields = Object.keys(formOne.control._fields).filter(
      key => formOne.control._fields[key]?._f.required
    );
    const validFulledField = requiredFields.filter(rf => {
      return !formOne.getFieldState(rf).invalid && formOne.watch(rf);
    });
    setBarPercent(validFulledField.length * (100 / requiredFields.length));
  }, [formOne.watch()]);

  useEffect(() => {
    if (activeTab !== undefined) {
      setActiveId(activeTab);
    }
    setBarPercent2(activeTab * 100);
  }, [activeTab]);

  useEffect(() => {
    if (createdBank) formOne.setValue('bank_id', createdBank.toString());
  }, [createdBank, setCreatedBank]);

  const onClickActive = (id: number) => {
    setActiveId(id);
  };

  const onReset = () => {
    formOne.reset();
    formTwo.reset();
  };

  return (
    <>
      <ModalTemplate
        isConfirmationNeeded={isConfirmationNeeded}
        close={close}
        isOpen={isOpen && !isBankOpen}
        onBack={activeTab === 1 ? () => onClickActive(0) : undefined}
        titleText={
          activeId === 1 ? 'Configuring the method' : methodData ? 'Edit method' : 'Add method'
        }
        hideBackdrop={false}
        reset={onReset}>
        <Box p={'30px 15px 0'}>
          <Tabs
            activeTab={activeId}
            onClick={onClickActive}
            disabledHeader={activeTab !== 1}
            headerTabs={[
              { id: 0, value: barPercent },
              { id: 1, value: barPercent2 }
            ]}>
            <FormWrapper
              onSubmit={() => {
                onNext?.();
                onClickActive(1);
              }}
              methods={formOne}
              padding=' 0'>
              <Box p={'30px 0'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                    <Grid item>
                      <Input
                        name='name'
                        methods={formOne}
                        label='Name*'
                        placeholder='Enter method name'
                        rules={{ required: 'Please, enter method name' }}
                      />
                    </Grid>
                    <Grid>
                      {/* <Select TODO убрали выбор transaction_source
                        name='transaction_source'
                        placeholder='Transaction source'
                        label='Transaction source *'
                        options={SOURCE_LIST}
                        rules={{ required: 'Please, choose transaction source' }}
                      /> */}
                      <Input
                        autofill
                        defaultValue={'SMS/push'}
                        name='transaction_source'
                        label='Transaction source'
                        placeholder='SMS/push'
                        methods={formOne}
                      />
                    </Grid>
                    <Grid>
                      <SelectSearch
                        options={banksLoaded}
                        key={JSON.stringify(banksLoaded)}
                        inputLabel='Bank'
                        inputPlaceholder='Select bank'
                        isOptionsLoading={isBanksLoading || isBanksFetching}
                        methods={formOne}
                        name='bank_id'
                        headerProps={{
                          label: 'Add bank',
                          onClick: () => {
                            setIsBankOpen(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        methods={formOne}
                        name='min_value'
                        label='Min value*'
                        type='number'
                        placeholder='Enter minimal sum'
                        rules={{
                          required: 'Please, enter minimal sum',
                          pattern: {
                            value: REGEX.INTEGER_POS,
                            message: 'Enter positive float number greater than 1'
                          }
                        }}
                      />
                    </Grid>
                    <Grid item sx={{ maxHeight: '350px' }}>
                      <SelectSearch
                        options={currency?.map(i => {
                          return { label: `${i.code}, (${i.symbol})`, value: i.code };
                        })}
                        isOptionsLoading={isCurrencyLoading}
                        inputLabel='Currency*'
                        inputPlaceholder='Choose currency for payment method'
                        methods={formOne}
                        name='currency'
                        rules={{ required: 'Please, choose currency' }}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        methods={formOne}
                        name='decline_time'
                        label='Cancellation time (hours)*'
                        type='number'
                        placeholder='Enter decline time, h'
                        rules={{
                          required: 'Please, enter decline time, h',
                          pattern: {
                            value: REGEX.FLOAT_POS,
                            message: 'Enter positive float number greater than 0.1'
                          }
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Input
                        methods={formOne}
                        name='ussd_command'
                        label='USSD command'
                        placeholder='Enter USSD command for payment method'
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display={'flex'} gap='10px'>
                  <Button
                    fullWidth
                    variant='contained'
                    color='info'
                    onClick={() => close()}
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3
                    }}>
                    Cancel
                  </Button>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    disabled={barPercent !== 100}
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3,
                      '&.Mui-disabled': {
                        backgroundColor: 'rgba(24, 90, 194, 0.2)',
                        color: 'rgba(24, 90, 194, 0.4)'
                      }
                    }}>
                    Next
                  </Button>
                </Box>
              </Box>
            </FormWrapper>
            <FormWrapper onSubmit={onSubmit} methods={formTwo} padding=' 0'>
              <Box p={'30px 0'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                  <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
                    <Grid item>
                      <SelectSearch
                        methods={formTwo}
                        name='compare_field'
                        inputPlaceholder='Select comparisons'
                        inputLabel='Сomparisons'
                        options={[
                          { value: 'remarks', label: 'Remarks' },
                          { value: 'amount', label: 'Amount' },
                          { value: 'amount_balance', label: 'Amount with balance' }
                          // { value: 'remarks_amount', label: 'Amount with remarks' },
                        ]}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        methods={formTwo}
                        name='regex'
                        label='Regular expressions'
                        placeholder='Enter regular expressions'
                        isEdit
                        onClickIcon={onRegexEditor}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box display={'flex'} gap='10px'>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    sx={{
                      borderRadius: 50,
                      textTransform: 'none',
                      height: 40,
                      marginTop: 15,
                      marginBottom: 3
                    }}>
                    Save
                  </Button>
                </Box>
              </Box>
            </FormWrapper>
          </Tabs>
        </Box>
      </ModalTemplate>

      <BankModal
        isOpen={isBankOpen}
        setIsOpen={setIsBankOpen}
        bankData={bankData!}
        onClose={onCloseUserModal}
        setCreatedBank={setCreatedBank}
      />
    </>
  );
};
