import { ChangeEvent, FC, useEffect, useState } from 'react';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, TableCell, TableRow } from '@mui/material';
import type { ClientRectObject } from '@popperjs/core';
import { useAuthStore } from 'app/store';
import { useNavigate } from 'react-router-dom';

import { UserAccountAddIcon, UserAccountIcon } from 'shared/assets/icons';
import { ROLE_USER } from 'shared/constants';
import { TMethods } from 'shared/services/types/methods.types';
import { TUsers } from 'shared/services/types/users.types';
import { StyledSwitch } from 'shared/ui/switch-styled';
import { CustomTooltip } from 'shared/ui/tooltip';

interface IProps {
  id: number;
  row: TUsers.UserData;
  usersData: TUsers.GetUsersSuccess;
  methodsItems?: TMethods.MethodItem[];
  onEditUser: (data: TUsers.UserData) => void;
  setSwitchItem: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
  setModalAccountAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setUserMethodID: React.Dispatch<React.SetStateAction<number | null>>;
  setUserID: React.Dispatch<React.SetStateAction<number | null>>;
}

export const UsersTableRow: FC<IProps> = ({
  id,
  usersData,
  row,
  methodsItems,
  onEditUser,
  setSwitchItem,
  setModalAccountAdd,
  setUserMethodID,
  setUserID
}) => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [checked, setChecked] = useState<boolean>(row.is_active);

  return (
    <TableRow key={JSON.stringify(row)}>
      <TableCell component='th' scope='row'>
        {id + 1 + usersData?.page * usersData?.size}
      </TableCell>
      <TableCell align='left'>{row.email}</TableCell>
      <TableCell component='th' scope='row'>
        {row.name}
      </TableCell>
      <TableCell align='left'>
        {methodsItems?.find(item => item.id === row.method_id)?.name}
      </TableCell>
      <TableCell align='left'>{ROLE_USER.find(item => item.label === row.role)?.value}</TableCell>
      <TableCell align='left' sx={{ cursor: 'pointer' }}>
        <Box display={'flex'} gap={8} justifyContent={'flex-end'} sx={{ color: '#AEAAAB' }}>
          {user &&
            ['Администратор', 'Менеджер'].includes(user?.role) &&
            ['Агент', 'Приложение'].includes(row.role) && (
              <CustomTooltip
                title={row.has_accounts ? 'User accounts' : 'Add user accounts'}
                optionsOffset={({ popper }: { popper: ClientRectObject }) => {
                  return [-popper.width * 0.3, 0];
                }}>
                {row.has_accounts ? (
                  <UserAccountIcon
                    onClick={() => {
                      navigate(`/user-accounts/${row.id}/${row.method_id}`);
                    }}
                    style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                  />
                ) : (
                  <UserAccountAddIcon
                    onClick={() => {
                      setUserMethodID(row.method_id);
                      setUserID(row.id);
                      setModalAccountAdd(true);
                    }}
                    style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
                  />
                )}
              </CustomTooltip>
            )}
          <CustomTooltip
            title={'Edit user'}
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [-popper.width * 0.3, 0];
            }}>
            <ModeEditOutlineOutlinedIcon
              onClick={() => {
                onEditUser(row);
              }}
              style={{ color: '##AEAAAB', width: '24px', height: '24px' }}
            />
          </CustomTooltip>
          <CustomTooltip
            title={`Turn off the acceptance of applications and transactions from the user`}
            optionsOffset={({ popper }: { popper: ClientRectObject }) => {
              return [-popper.width * 0.3, 0];
            }}>
            <Box>
              <StyledSwitch
                value={!!checked}
                setValue={e => {
                  setSwitchItem(e, row.id);
                  setChecked(!e.target.checked);
                }}
              />
            </Box>
          </CustomTooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};
