import { Banks } from 'widgets/banks';

import { Layout } from 'shared/ui/layout';

const BanksPage = () => {
  return (
    <Layout bankName='Banks' isSingleMethod hiddenHeader>
      <Banks />
    </Layout>
  );
};

export default BanksPage;
