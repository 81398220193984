export const ROLE_USER = [
  {
    label: 'Администратор',
    value: 'Admin'
  },
  {
    label: 'Менеджер',
    value: 'Manager'
  },
  {
    label: 'Агент',
    value: 'Agent'
  }
];

export const CURRENCY = [
  { value: 'AED', label: 'AED, (د.إ)' },
  { value: 'AFN', label: 'AFN' },
  { value: 'ALL', label: 'ALL, (Lek)' },
  { value: 'AMD', label: 'AMD, (֏)' },
  { value: 'ANG', label: 'ANG, (ƒ)' },
  { value: 'AOA', label: 'AOA, (Kz)' },
  { value: 'ARS', label: 'ARS, ($)' },
  { value: 'AUD', label: 'AUD, (AU$)' },
  { value: 'AWG', label: 'AWG, (ƒ)' },
  { value: 'AZN', label: 'AZN, (₼)' },
  { value: 'BAM', label: 'BAM, (KM)' },
  { value: 'BBD', label: 'BBD, (B$)' },
  { value: 'BDT', label: 'BDT, (৳)' },
  { value: 'BGN', label: 'BGN, (лв.)' },
  { value: 'BHD', label: 'BHD, (د.ب)' },
  { value: 'BIF', label: 'BIF, (FBu)' },
  { value: 'BMD', label: 'BMD, (BD$)' },
  { value: 'BND', label: 'BND, (B$)' },
  { value: 'BOB', label: 'BOB, (Bs)' },
  { value: 'BRL', label: 'BRL, (R$)' },
  { value: 'BSD', label: 'BSD, (BS$)' },
  { value: 'BTN', label: 'BTN, (Nu)' },
  { value: 'BWP', label: 'BWP, (P)' },
  { value: 'BYN', label: 'BYN, (р.)' },
  { value: 'BZD', label: 'BZD, (BZ$)' },
  { value: 'CAD', label: 'CAD, (CA$)' },
  { value: 'CHF', label: 'CHF, (CHF)' },
  { value: 'CLP', label: 'CLP, (CLP)' },
  { value: 'CNY', label: 'CNY, (CN¥)' },
  { value: 'COP', label: 'COP, (CO$)' },
  { value: 'CRC', label: 'CRC, (₡)' },
  { value: 'CVE', label: 'CVE, (CV$)' },
  { value: 'CZK', label: 'CZK, (czk)' },
  { value: 'DJF', label: 'DJF, (Fdj)' },
  { value: 'DKK', label: 'DKK, (kr)' },
  { value: 'DOP', label: 'DOP, (RD$)' },
  { value: 'DZD', label: 'DZD, (DA) ' },
  { value: 'EGP', label: 'EGP, (£)' },
  { value: 'ERN', label: 'ERN, (Nfk)' },
  { value: 'ETB', label: 'ETB, (Br)' },
  { value: 'EUR', label: 'EUR, (€)' },
  { value: 'FJD', label: 'FJD, (F$)' },
  { value: 'GBP', label: 'GBP, (£)' },
  { value: 'GEL', label: 'GEL, (ლ)' },
  { value: 'GHS', label: 'GHS, (GH¢)' },
  { value: 'GIP', label: 'GIP, (£)' },
  { value: 'GTQ', label: 'GTQ, (QT)' },
  { value: 'GYD', label: 'GYD, (GY$)' },
  { value: 'HKD', label: 'HKD, (HK$)' },
  { value: 'HNL', label: 'HNL, (L)' },
  { value: 'HRK', label: 'HRK, (kn)' },
  { value: 'HTG', label: 'HTG, (G)' },
  { value: 'HUF', label: 'HUF, (Ft)' },
  { value: 'IDR', label: 'IDR, (Rp)' },
  { value: 'ILS', label: 'ILS, (₪)' },
  { value: 'INR', label: 'INR, (₹)' },
  { value: 'IQD', label: 'IQD, (ع.د)' },
  { value: 'IRR', label: 'IRR, (﷼)' },
  { value: 'ISK', label: 'ISK, (kr)' },
  { value: 'JMD', label: 'JMD, (J$)' },
  { value: 'JOD', label: 'JOD, (£)' },
  { value: 'JPY', label: 'JPY, (¥)' },
  { value: 'KES', label: 'KES, (KSh)' },
  { value: 'KHR', label: 'KHR, (៛)' },
  { value: 'KMF', label: 'KMF, (FMG)' },
  { value: 'KPW', label: 'KPW, (₩)' },
  { value: 'KRW', label: 'KRW, (₩)' },
  { value: 'KWD', label: 'KWD, (د.ك)' },
  { value: 'KYD', label: 'KYD, (KY$)' },
  { value: 'KZT', label: 'KZT, (лв.)' },
  { value: 'LAK', label: 'LAK, (₭)' },
  { value: 'LBP', label: 'LBP, (£)' },
  { value: 'LRD', label: 'LRD, (LR$)' },
  { value: 'LSL', label: 'LSL, (LS)' },
  { value: 'LYD', label: 'LYD, (ل.د)' },
  { value: 'MAD', label: 'MAD, (₸)' },
  { value: 'MDL', label: 'MDL, (лв.)' },
  { value: 'MGA', label: 'MGA, (Ar)' },
  { value: 'MKD', label: 'MKD, (ден.)' },
  { value: 'MMK', label: 'MMK, (₨)' },
  { value: 'MOP', label: 'MOP, (P)' },
  { value: 'MRO', label: 'MRO, (UM)' },
  { value: 'MUR', label: 'MUR, (₨)' },
  { value: 'MVR', label: 'MVR, (₨)' },
  { value: 'MWK', label: 'MWK, (MK)' },
  { value: 'MXN', label: 'MXN, (Mex$)' },
  { value: 'MYR', label: 'MYR, (RM)' },
  { value: 'MZN', label: 'MZN, (MTn)' },
  { value: 'NAD', label: 'NAD, (NA$)' },
  { value: 'NGN', label: 'NGN, (₦)' },
  { value: 'NIO', label: 'NIO, (C$' },
  { value: 'NOK', label: 'NOK, (kr)' },
  { value: 'NRG', label: 'NRG' },
  { value: 'NZD', label: 'NZD, (NZ$)' },
  { value: 'OMR', label: 'OMR, (ر.ع.)' },
  { value: 'PAB', label: 'PAB, (B$)' },
  { value: 'PEN', label: 'PEN, (S/.)' },
  { value: 'PGK', label: 'PGK, (K)' },
  { value: 'PHP', label: 'PHP, (₱)' },
  { value: 'PKR', label: 'PKR, (Rs)' },
  { value: 'PLN', label: 'PLN, (zł)' },
  { value: 'PYG', label: 'PYG, (Gs)' },
  { value: 'QAR', label: 'QAR, (ر.ق)' },
  { value: 'RON', label: 'RON, (lei)' },
  { value: 'RSD', label: 'RSD, (дин.)' },
  { value: 'RUB', label: 'RUB, (руб.)' },
  { value: 'RWF', label: 'RWF, (Rfr)' },
  { value: 'SAR', label: 'SAR, (ر.س)' },
  { value: 'SBD', label: 'SBD, (SB$)' },
  { value: 'SCR', label: 'SCR, (₨)' },
  { value: 'SDG', label: 'SDG, (£)' },
  { value: 'SEK', label: 'SEK, (kr)' },
  { value: 'SGD', label: 'SGD, (S$)' },
  { value: 'SHP', label: 'SHP, (£)' },
  { value: 'SLL', label: 'SLL, (Le)' },
  { value: 'SOS', label: 'SOS, (Sh. So.)' },
  { value: 'SRD', label: 'SRD, (SR$)' },
  { value: 'SSP', label: 'SSP, (£)' },
  { value: 'STD', label: 'STD, (Db)' },
  { value: 'SVC', label: 'SVC, (GVG)' },
  { value: 'SYP', label: 'SYP, (£)' },
  { value: 'SZL', label: 'SZL, (L)' },
  { value: 'THB', label: 'THB, (฿)' },
  { value: 'TJS', label: 'TJS, (сум)' },
  { value: 'TKM', label: 'TKM, (лв.)' },
  { value: 'TMT', label: 'TMT, (m)' },
  { value: 'TND', label: 'TND, (د.ت)' },
  { value: 'TOP', label: 'TOP, (T$' },
  { value: 'TRY', label: 'TRY, (₺)' },
  { value: 'TTD', label: 'TTD, (TT$)' },
  { value: 'TWD', label: 'TWD, (NT$)' },
  { value: 'TZS', label: 'TZS, (Sh)' },
  { value: 'UAH', label: 'UAH, (₴)' },
  { value: 'UGX', label: 'UGX, (USh)' },
  { value: 'USD', label: 'USD, ($)' },
  { value: 'USN', label: 'USN, (¤)' },
  { value: 'UST', label: 'UST, (¢)' },
  { value: 'UZS', label: 'UZS, (лв.)' },
  { value: 'VEF', label: 'VEF, (Bs)' },
  { value: 'VND', label: 'VND, (₫)' },
  { value: 'VUV', label: 'VUV, (VT)' },
  { value: 'WST', label: 'WST, (WS$)' },
  { value: 'XAF', label: 'XAF, (FCFA)' },
  { value: 'XAG', label: 'XAG, (£)' },
  { value: 'XAU', label: 'XAU, (£)' },
  { value: 'XBA', label: 'XBA, (£)' },
  { value: 'XBB', label: 'XBB, (£)' },
  { value: 'XBC', label: 'XBC, (£)' },
  { value: 'XBD', label: 'XBD, (£)' },
  { value: 'XDR', label: 'XDR, (SDR)' },
  { value: 'XOF', label: 'XOF, (CFA)' },
  { value: 'XPD', label: 'XPD, (£)' },
  { value: 'XPF', label: 'XPF, (CFP)' },
  { value: 'XPT', label: 'XPT, (£)' },
  { value: 'XSU', label: 'XSU' },
  { value: 'XTS', label: 'XTS' },
  { value: 'XUA', label: 'XUA' },
  { value: 'XXX', label: 'XXX' },
  { value: 'YER', label: 'YER, (ر.ي)' },
  { value: 'ZAR', label: 'ZAR, (R)' },
  { value: 'ZMW', label: 'ZMW, (K)' },
  { value: 'ZWL', label: 'ZWL, (Z$)' }
];
