import { toFormData } from 'axios';

export function createFormData<T>(data: T): FormData {
  // Функция для фильтрации объекта
  const filterObject = (obj: T): Partial<T> => {
    const filtered: Partial<T> = {};
    for (const key in obj) {
      if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
        filtered[key] = obj[key];
      }
    }
    return filtered;
  };

  // Фильтруем данные
  const filteredData = filterObject(data);

  //@ts-ignore
  return toFormData(filteredData);
}
